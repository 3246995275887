'use strict';

/* global jQuery, TweenMax, TimelineLite, tinycolor*/

// Settings
var debug = false;
//SBTODO: Make sure debug is set to false on launch

/**
 * Get an array of an objects properties
 * @param object
 * @returns {Array}
 */
function getObjectProperties(object) {
    var array = [];

    for (var property in object) {
        if (object.hasOwnProperty(property)) {
            array.push(property);
        }
    }

    return array;
}

/**
 * User Agent check
 * @type {{Windows: isMobile.Windows, Android: isMobile.Android, BlackBerry: isMobile.BlackBerry, iOS: isMobile.iOS, any: isMobile.any}}
 */
var mobileCheck = {
    Windows: function Windows() {
        return (/IEMobile/i.test(navigator.userAgent)
        );
    },
    Android: function Android() {
        return (/Android/i.test(navigator.userAgent)
        );
    },
    BlackBerry: function BlackBerry() {
        return (/BlackBerry/i.test(navigator.userAgent)
        );
    },
    iOS: function iOS() {
        return (/iPhone|iPad|iPod/i.test(navigator.userAgent)
        );
    },
    any: function any() {
        return mobileCheck.Android() || mobileCheck.BlackBerry() || mobileCheck.iOS() || mobileCheck.Windows();
    }
};

/**
 * Google Analytics event tracking
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 *
 * @returns {Utilities}
 */
function trackAnalyticEvent(category, action, label) {

    if (typeof ga !== 'undefined') {
        ga('send', 'event', category, action, label, {
            'page': decodeURI(window.location),
            'nonInteraction': 1
        });
    }

    if (debug) {
        console.log('Analytics Events --> ', category, action, label);
    }

    return this;
}

/**
 * jQuery
 */

jQuery.fn.reverse = [].reverse;

(function ($) {
    'use strict';

    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var viewportTop = $(document).scrollTop();
    var viewportBottom = viewportTop + windowHeight;

    // Reverse the layers so that they index in the order you view them
    var $body = $('body');

    // Page Elements
    var $leftSidebar = $('.js-left-sidebar');
    var $navItems = $('.js-nav__item');
    var $rightSidebar = $('.js-right-sidebar');
    var $rightOverlay = $('.js-right-overlay');
    var $explore = $('.js-explore');
    var $caseStudies = $('.js-case-studies');
    var $caseStudyButtons = $('.js-case-study-btn');
    var $caseStudyPins = $('.js-case-study-pin');
    var $overlayPrevButton = $('.js-overlay-prev');
    var $overlayNextButton = $('.js-overlay-next');
    var $introClose = $('.js-intro-close');
    var $introOverlay = $('.js-intro-overlay');
    var $introBox = $('.js-intro-box');
    var $mobileClose = $('.js-mobile-close');
    var $mobileOverlay = $('.js-mobile-overlay');
    var $customScrollbars = $('.js-scrollbar-right, .js-scrollbar-right-overlay');
    var $menuOverlay = $('.js-menu-overlay');
    var $hamburger = $('.js-burger');

    // SVG Elements
    var $svg = $('.js-svg-container svg');
    var $layers = $('.js-layer').reverse();
    var $pins = $('.js-pin');
    var $tooltips = $('.js-tooltip');

    // Settings
    var slideAnimationSpeed = 0.6;
    var slideAnimationDelay = 0.6;
    var slideFadeSpeed = 0.3;
    var slideFadeDelay = 0.3;
    var slideScale = 0.45;

    // Variables
    var isMobile = false;
    var init = false;
    var isOpen = false;
    var isTweening = false;
    var isCaseStudy = false;
    var contentStore = [];
    var currentLayer = void 0;
    var currentOverlay = void 0;
    var currentCaseStudy = void 0;
    var colourTitles = {
        'reset': 'System Overview',
        'white': 'System Overview',
        'blue': 'Key capabilities',
        'green': 'Delivery partners',
        'yellow': 'Decision makers',
        'orange': 'Knowledge base',
        'pink': 'Day-to-day city'
    };

    if (windowWidth < 1024) {
        isMobile = true;

        $mobileOverlay.show();
    }

    if (mobileCheck.any()) {
        isMobile = true;
    }

    $(window).on('load', function () {
        windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        loadPageContents();

        // Save layer position values on load
        // This is needed as a reference for where TweenMax's Y axis is based.
        $layers.each(function () {
            // Save the bottom position on load
            $(this).data('startBottom', $(this)[0].getBoundingClientRect().bottom);

            // Save the top position on load
            $(this).data('startTop', $(this)[0].getBoundingClientRect().top);
        });

        // Page is initialised
        init = true;

        $customScrollbars.perfectScrollbar();
    }).on('resize', function () {
        $customScrollbars.perfectScrollbar('update');
    });

    // Layer Manipulation
    // -------------------------------------------------------------------------------------------------
    /**
     * Reset the Layers to their starting position
     */
    function resetLayers() {
        'use strict';

        resetLayerPositions(1);

        hideCaseStudies();

        closeOverlayRight();

        // Update Navigation highlighting
        $('.js-nav__item').removeClass('is-active');
        $('.js-nav__item--white').addClass('is-active');

        // Reset page contents
        applyPageContent('title', ['general']);
        applyPageContent('content', ['general']);

        $customScrollbars.perfectScrollbar('update');
    }

    function resetLayerPositions() {
        var delay = arguments.length <= 0 || arguments[0] === undefined ? 0.5 : arguments[0];

        isTweening = true;
        isOpen = false;

        // Remove active classes
        TweenMax.set($layers, { className: '-=is-active' });

        if (isMobile) {
            TweenMax.set($layers, {
                onComplete: function onComplete() {
                    TweenMax.set($layers.find('g:not([class*="js-pin_"])'), { opacity: 1 });

                    $pins.css('opacity', 0).removeAttr('data-recolor');

                    isTweening = false;
                    isCaseStudy = false;
                    currentLayer = null;
                    currentOverlay = null;
                },
                className: '-=is-small',
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: 0
            });
        } else {
            // Tween all layers back to start position
            TweenMax.to($layers, slideAnimationSpeed, {
                onComplete: function onComplete() {
                    TweenMax.to($layers.find('g:not([class*="js-pin_"])'), slideFadeSpeed, { opacity: 1 });

                    $pins.css('opacity', 0).removeAttr('data-recolor');

                    isTweening = false;
                    isCaseStudy = false;
                    currentLayer = null;
                    currentOverlay = null;
                },
                className: '-=is-small',
                delay: delay,
                scale: 1,
                opacity: 1,
                transformOrigin: "center bottom",
                y: 0
            });
        }
    }

    /**
     * Clicking on a layer when in their start position / reset position
     * @param $clickedEle
     */
    function initialStack($clickedEle) {
        'use strict';

        TweenMax.set($layers, { className: '-=is-active' });
        TweenMax.set($clickedEle, { className: '+=is-active' });

        // Find whether the next layer to the active one needs
        // to be moved to the top or bottom of the viewport
        var $layerToShow = $clickedEle;
        var topOrBottom = 'top';
        var i = 0;
        var j = 0;

        $layers.each(function () {
            if ($(this).is($layerToShow)) {
                // Switch bottom to top
                topOrBottom = 'bottom';

                centreToViewport($(this), i);

                i = 0;
            } else {
                // Move inactive layers that are before the active layer to the top
                if (topOrBottom === 'top') {
                    moveLayerTop($(this), i);
                }
                // Move inactive layers that are after the active layer to the bottom
                else if (topOrBottom === 'bottom') {
                        moveLayerBottom($(this), j);
                    }
            }

            j++;
            i++;
        });

        isOpen = true;
    }

    /**
     * Clicking on a layer when one is currently active/centred
     * @param $clickedEle
     */
    function restack($clickedEle) {
        'use strict';

        var currentLayer = $layers.index($svg.find('.is-active'));
        var clickedLayer = $layers.index($clickedEle);
        var layersToMove = [];

        // If the layer that you've clicked on is above the current layer
        if (clickedLayer - currentLayer <= 0) {
            // Finding all of the layers that sit in-between the current layer and the clicked layer (above)
            for (var i = currentLayer; i >= clickedLayer; i--) {
                layersToMove.push(i);
            }
        }
        // Otherwise, the layer you've clicked on is below the current layer...
        else {
                // Finding all of the layers that sit in-between the current layer and the clicked layer (below)
                for (var _i = currentLayer; _i <= clickedLayer; _i++) {
                    layersToMove.push(_i);
                }
            }

        // For every instance of an in-between layer
        for (var _i2 = 0; _i2 < layersToMove.length; _i2++) {

            // Only do stuff if one of the in-between layers isn't the one that you've clicked on
            if (layersToMove[_i2] !== clickedLayer) {
                // Move to top or bottom

                // If the layer you've clicked is below the current layer, move any in-between layers to the top
                if (clickedLayer > currentLayer) {
                    moveLayerTop($($layers[layersToMove[_i2]]), layersToMove[_i2]);
                }
                // Otherwise, the clicked layer is above the current layer and any in-between layers should move to the bottom
                else {
                        moveLayerBottom($($layers[layersToMove[_i2]]), layersToMove[_i2]);
                    }
            }
            // The remaining value in the in-between layer array must be the one that has been clicked, so move that to the centre of the viewport
            else {
                    // Move to middle
                    centreToViewport($($layers[layersToMove[_i2]]), clickedLayer);
                }
        }

        // Add active classes
        TweenMax.set($layers, { className: '-=is-active' });
        TweenMax.set($clickedEle, { className: '+=is-active' });
    }

    /**
     * Move the specified layer to the centre of the screen
     *
     * @param $itemToCentre
     * @param i
     */
    function centreToViewport($itemToCentre) {
        var i = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];


        //const currentLayerPosition = $itemToCentre[0].getBoundingClientRect();
        var centreValueArray = [118, 44, -31, -105, -180];

        var yPosition = centreValueArray[i] + 50;

        if ($itemToCentre.attr('data-color') === 'pink') {
            yPosition += 80;
        }

        if (isMobile) {
            TweenMax.set($itemToCentre, {
                className: '-=is-small',
                opacity: 1,
                transformOrigin: "center center",
                scale: 1,

                onComplete: function onComplete() {
                    TweenMax.set($itemToCentre, {
                        y: yPosition,

                        onComplete: function onComplete() {
                            TweenMax.set($itemToCentre.find('g:not([class*="Base"])'), {
                                onComplete: function onComplete() {
                                    isTweening = false;
                                    isCaseStudy = false;
                                },
                                opacity: 1
                            });
                        }
                    });
                }
            });
        } else {
            // Move active layer to center of viewport
            TweenMax.to($itemToCentre, slideAnimationSpeed, {
                className: '-=is-small',
                delay: slideAnimationDelay,
                opacity: 1,
                scale: 1,
                transformOrigin: "center center",
                y: yPosition,

                onComplete: function onComplete() {
                    TweenMax.set($itemToMove, {
                        transformOrigin: "centre center",
                        y: yPosition
                    });
                }
            });

            TweenMax.to($itemToCentre.find('g:not([class*="Base"])'), slideFadeSpeed, {
                onComplete: function onComplete() {
                    isTweening = false;
                    isCaseStudy = false;
                },
                delay: slideAnimationDelay * 2,
                opacity: 1
            });
        }

        if (debug) {
            console.log('--------------------------------------------------------');
            console.log($itemToCentre.data('color'), ' to centre');
            console.log(centreValueArray[i]);
        }

        // Update Navigation highlighting
        $('.js-nav__item').removeClass('is-active');
        $('.js-nav__item--' + $itemToCentre.data('color')).addClass('is-active');

        currentLayer = $itemToCentre.data('color');
    }

    /**
     * Move the specified layer to it's top position
     *
     * @param $itemToMove
     * @param i
     */
    function moveLayerTop($itemToMove) {
        var i = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

        // The bounding client rectangle object
        var currentLayerPosition = $itemToMove[0].getBoundingClientRect();

        // The current Y position of the layer
        var currentY = currentLayerPosition.top;

        // Height of the layer at 60%
        var smallHeight = currentLayerPosition.height / 2 * slideScale;

        if ($itemToMove.attr("class").indexOf('is-small') > -1) {
            smallHeight = currentLayerPosition.height / 2;
        }

        // Position change offset depending on which layer you're on (the spacing).
        var offsets = {
            0: 60,
            1: 40,
            2: 20,
            3: 0,
            4: 0
        };

        // Target Position
        var targetY = viewportTop - smallHeight - offsets[i];

        // How much the position changes
        var diffY = targetY - currentY;

        // If $itemToMove is currently small/at the top?
        if ($itemToMove.attr("class").indexOf('is-small') > -1) {
            diffY = targetY - $itemToMove.data('startTop');
        }

        // If $itemToMove is currently active/in the centre?
        if ($itemToMove.attr("class").indexOf('is-active') > -1) {
            // Adjust the Y difference by the amount of change of the currentY compared to the start Y
            diffY += currentY - $itemToMove.data('startTop');
        }

        if (debug) {
            console.log('--------------------------------------------------------');
            console.log($itemToMove.data('color'), ' to top');
            console.log(Math.floor(viewportTop), ' - ', Math.floor(smallHeight), ' - ', Math.floor(offsets[i]), ' = ', Math.floor(targetY));
            console.log(Math.floor(targetY), ' - ', Math.floor(currentY), ' = ', Math.floor(diffY));
            console.log(slideScale);
        }

        if (isMobile) {
            TweenMax.set($itemToMove, {
                className: '+=is-small',
                transformOrigin: "center top",
                scale: slideScale,

                onComplete: function onComplete() {
                    TweenMax.set($itemToMove.find('g:not([class*="Base"])'), {
                        opacity: 0
                    });

                    TweenMax.set($itemToMove, {
                        transformOrigin: "centre top",
                        y: diffY - 75
                    });
                }
            });
        } else {
            TweenMax.to($itemToMove.find('g:not([class*="Base"])'), slideFadeSpeed, {
                opacity: 0
            });

            TweenMax.to($itemToMove, slideAnimationSpeed, {
                className: '+=is-small',
                delay: slideFadeDelay,
                scale: slideScale,
                transformOrigin: "center top",
                y: diffY + 45,

                onComplete: function onComplete() {
                    TweenMax.set($itemToMove, {
                        transformOrigin: "centre top",
                        y: diffY + 45
                    });
                }
            });
        }
    }

    /**
     * Move the specified layer to it's bottom position
     *
     * @param $itemToMove
     * @param i
     */
    function moveLayerBottom($itemToMove) {
        var i = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

        // The bounding client rectangle object
        var currentLayerPosition = $itemToMove[0].getBoundingClientRect();

        // The current Y position of the layer
        var currentY = currentLayerPosition.bottom;

        // Height of the layer at 60%
        var smallHeight = currentLayerPosition.height / 2 * slideScale;

        if ($itemToMove.attr("class").indexOf('is-small') > -1) {
            smallHeight = currentLayerPosition.height / 2;
        }

        // Position change offset depending on which layer you're on (the spacing).
        var offsets = {
            1: 120,
            2: 100,
            3: 80,
            4: 60
        };

        // Target Position
        var targetY = viewportBottom + smallHeight - offsets[i];

        // How much the position changes
        var diffY = targetY - currentY;

        // If $itemToMove is currently small/at the top?
        if ($itemToMove.attr("class").indexOf('is-small') > -1) {
            diffY = targetY - $itemToMove.data('startBottom');
        }

        // If $itemToMove is currently active/in the centre?
        if ($itemToMove.attr("class").indexOf('is-active') > -1) {
            // Adjust the Y difference by the amount of change of the currentY compared to the start Y
            diffY -= $itemToMove.data('startBottom') - currentY;
        }

        if (debug) {
            console.log('--------------------------------------------------------');
            console.log($itemToMove.data('color'), ' to bottom');
            console.log(Math.floor(viewportBottom), ' + ', Math.floor(smallHeight), ' - ', Math.floor(offsets[i]), ' = ', Math.floor(targetY));
            console.log(Math.floor(targetY), ' - ', Math.floor(currentY), ' = ', Math.floor(diffY));
            console.log(slideScale);
        }

        if (isMobile) {
            TweenMax.set($itemToMove, {
                className: '+=is-small',
                scale: slideScale,

                onComplete: function onComplete() {
                    TweenMax.set($itemToMove.find('g:not([class*="Base"])'), {
                        opacity: 0
                    });

                    TweenMax.set($itemToMove, {
                        transformOrigin: "centre bottom",
                        y: diffY
                    });
                }
            });
        } else {
            TweenMax.to($itemToMove.find('g:not([class*="Base"])'), slideFadeSpeed, {
                opacity: 0
            });

            TweenMax.to($itemToMove, slideAnimationSpeed, {
                className: '+=is-small',
                delay: slideFadeDelay,
                scale: slideScale,
                transformOrigin: "centre bottom",
                y: diffY,

                onComplete: function onComplete() {
                    TweenMax.set($itemToMove, {
                        transformOrigin: "centre bottom",
                        y: diffY
                    });
                }
            });
        }
    }

    // Layer click functionality
    $layers.on('click', function () {
        // If page is initialised and is not currently tweening
        if (init && !isTweening) {
            isTweening = true;

            // Add colour class to body
            $body.removeClass().addClass($(this).data('color') + '-bg');

            // Close right sidebar
            closeOverlayRight();

            // Either run an initial stack opener or a restack
            if (!isOpen) {
                initialStack($(this));
            } else {
                restack($(this));
            }

            applyPageContent('title', [$(this).data('color')]);
            applyPageContent('content', [$(this).data('color')]);

            // Google Analytics Event tracking
            trackAnalyticEvent('Layer', 'Opened', colourTitles[$(this).data('color')]);

            $customScrollbars.perfectScrollbar('update');
        }
    });

    // Layer Pin Functionality
    // -------------------------------------------------------------------------------------------------
    /**
     * Pin Target rollover color manipulation
     * @param $pin
     */
    function updatePinTargetColor($pin) {
        if ($pin.attr('data-recolor') !== 'true') {
            $pin.parent().find('.js-pin-target *').each(function () {
                var thisColor = $(this).css('fill');

                var newColor = thisColor;

                if (thisColor && thisColor !== 'none') {

                    switch (currentLayer) {
                        case 'blue':
                            newColor = tinycolor(thisColor).darken(10).desaturate(50).spin(0).toHexString();
                            break;

                        case 'green':
                            if (tinycolor(thisColor).getBrightness() > 80) {
                                newColor = tinycolor(thisColor).darken(15).desaturate(37).spin(130).toHexString();
                            } else {
                                newColor = tinycolor(thisColor).darken(5).desaturate(37).spin(130).toHexString();
                            }
                            break;

                        case 'yellow':
                            if (tinycolor(thisColor).getBrightness() > 80) {
                                newColor = tinycolor(thisColor).darken(15).desaturate(75).spin(150).toHexString();
                            } else {
                                newColor = tinycolor(thisColor).darken(5).desaturate(75).spin(150).toHexString();
                            }
                            break;

                        case 'orange':
                            if (tinycolor(thisColor).getBrightness() > 80) {
                                newColor = tinycolor(thisColor).darken(10).desaturate(68).spin(180).toHexString();
                            } else {
                                newColor = tinycolor(thisColor).darken(5).desaturate(68).spin(180).toHexString();
                            }
                            break;

                        case 'pink':
                            if (tinycolor(thisColor).getBrightness() > 80) {
                                newColor = tinycolor(thisColor).darken(15).desaturate(45).spin(235).toHexString();
                            } else {
                                newColor = tinycolor(thisColor).darken(5).desaturate(45).spin(235).toHexString();
                            }
                            break;

                        default:
                            newColor = tinycolor(thisColor).darken(5).desaturate(45).toHexString();
                            break;
                    }

                    $(this).css('fill', newColor);
                }
            });
        }

        $pin.attr('data-recolor', 'true');
    }

    /**
     * SVG Pin actions
     * SBTODO: Optimise the * selector
     */
    $('.js-pin, .js-pin-target').on('mouseenter', function () {
        var $pinInGroup = $(this).parent().find('.js-pin');

        if ($(this).parents('.js-layer').attr("class").indexOf('is-active') > -1 && $pinInGroup.data('state') !== 'active') {
            updatePinTargetColor($pinInGroup);
        }
    }).on('mouseleave', function () {
        var $pinInGroup = $(this).parent().find('.js-pin');

        if ($pinInGroup.attr('data-state') !== 'active') {
            $pinInGroup.removeAttr('data-recolor').parent().find('.js-pin-target *').removeAttr('style');
        }
    }).on('click', function (e) {
        e.stopPropagation();
        var $pinInGroup = $(this).parent().find('.js-pin');

        var clickedPinsLayer = $(this).parents('.js-layer').data('color');

        if (currentLayer !== null && currentLayer !== undefined && clickedPinsLayer === currentLayer) {
            // What pin has been clicked?
            var clickedPin = $(this).parent().attr('id');

            currentCaseStudy = null;
            currentOverlay = clickedPin;

            if (debug) {
                console.log(clickedPin);
            }

            // Set the target colours
            updatePinTargetColor($pinInGroup);

            // Set this pin to active.
            $pinInGroup.attr('data-state', 'active');

            // Reset all other pins
            $pins.not($pinInGroup).attr('data-state', null).removeAttr('data-recolor').parent().find('.js-pin-target *').removeAttr('style');

            // Apply the Title
            var title = applyPinContent('title', [currentLayer, 'pins', clickedPin]);

            // Apply the content
            applyPinContent('content', [currentLayer, 'pins', clickedPin]);

            // Google Analytics Event tracking
            trackAnalyticEvent('Layer pin', 'clicked', title);

            $customScrollbars.perfectScrollbar('update');

            $rightOverlay.addClass('is-visible');

            $rightSidebar.scrollTop(0);
        }
    });

    // Content manipulation
    // -------------------------------------------------------------------------------------------------
    /**
     * Function to fire a number of json requests
     */
    function loadPageContents() {
        'use strict';

        // Staging
        //const jsonFile = "assets/json/content_json.php";

        // Production

        var jsonFile = "assets/json/content.json";

        $.getJSON(jsonFile, function (data) {
            contentStore = data;

            applyPageContent('title', ['general']);
            applyPageContent('content', ['general']);

            $customScrollbars.perfectScrollbar('update');

            setTimeout(function () {
                $svg.css('opacity', '1');
            }, 1);
        });
    }

    function getContentFromStore(keys) {
        'use strict';

        var content = contentStore;

        for (var i = 0; i < keys.length; i++) {
            if (content[keys[i]]) {
                content = content[keys[i]];
            } else {
                break;
            }
        }

        return content;
    }

    function applyPinContent(element, keys) {
        'use strict';

        if (debug) {
            console.log(element, keys);
            //console.log(getContentFromStore(keys)[element]);
        }

        var content = getContentFromStore(keys)[element];

        $('[data-id="' + element + '"]', '.js-right-overlay').html(content);

        return content;
        //
    }

    function applyPageContent(element, keys) {
        'use strict';

        var showExplore = getContentFromStore(keys)['show_explore'];

        if (showExplore && showExplore === 'false') {
            $rightSidebar.parent().removeClass('show-explore');
        } else {
            $rightSidebar.parent().addClass('show-explore');
        }

        // Reset scrollTop of the div
        $rightSidebar.scrollTop(0);

        if (debug) {
            console.log(element, keys);
            //console.log(getContentFromStore(keys)[element]);
        }

        var content = getContentFromStore(keys)[element];

        $('[data-id="' + element + '"]', '.js-right-sidebar').html(content);

        if (currentCaseStudy) {
            $rightSidebar.parent().addClass('is-case-study');
        } else {
            $rightSidebar.parent().removeClass('is-case-study');
        }

        return content;
    }

    /**
     * Close the right overlay sidebar
     */
    function closeOverlayRight() {
        'use strict';

        // Reset pins and pin targets

        $pins.attr('data-state', null).removeAttr('data-recolor').parent().find('.js-pin-target *').removeAttr('style');

        currentOverlay = null;

        // Close right
        $rightOverlay.removeClass('is-visible');

        $caseStudyPins.removeClass('is-selected');
    }

    // Left Sidebar
    // -------------------------------------------------------------------------------------------------
    /**
     * Left navigation click actions to select the layer
     */
    $navItems.on('click', function () {
        var target = $(this).data('targetColor');

        currentCaseStudy = null;
        $caseStudyButtons.removeClass('is-active');

        closeOverlayRight();

        hideCaseStudies();

        if (target === 'reset') {
            resetLayers();
        } else {
            $svg.find('g[data-color=' + target + ']').trigger('click');
        }

        // Google Analytics Event tracking
        trackAnalyticEvent('Layer', 'Opened', colourTitles[target]);
    });

    /**
     * Left Sidebar info button toggle
     */
    $('.js-info-btn').on('click', function () {
        $(this).toggleClass('is-active');
        $('.js-info-box').slideToggle();

        // Google Analytics Event tracking
        trackAnalyticEvent('Information button', 'Clicked', null);
    });

    $('.js-download-btn').on('click', function () {
        // Google Analytics Event tracking
        trackAnalyticEvent('Download button', 'Clicked', null);
    });

    /**
     * Left Sidebar expand functionality
     */
    $hamburger.on('click', function () {
        $leftSidebar.toggleClass('is-expanded');
        $(this).toggleClass('is-cross');
        $menuOverlay.fadeToggle();
    });

    /**
     * Left sidebar overlay toggle
     */
    $menuOverlay.on('click', function () {
        if ($leftSidebar.hasClass('is-expanded')) {
            $leftSidebar.toggleClass('is-expanded');
            $hamburger.toggleClass('is-cross');
            $menuOverlay.fadeToggle();
        }
    });

    /**
     * Tooltipable element hover on/off
     */
    $tooltips.on('mouseenter', function () {
        if (!$leftSidebar.hasClass('is-expanded')) {
            $('.js-tooltip-box').remove();

            var tooltipText = $(this).find('.js-tooltip-text').html();
            var topPosition = $(this).position().top + $(this).outerHeight() / 2;

            var $tooltip = $('<div class="tooltip ts-nav-case-study js-tooltip-box">' + tooltipText + '</div>').css({
                'top': topPosition
            });

            $leftSidebar.after($tooltip);

            $tooltip.css('margin-top', $tooltip.height() / 2 * -1 - 6);
        }
    }).on('mouseleave', function () {
        'use strict';

        $('.js-tooltip-box').remove();
    });

    // Right Sidebar & Overlay
    // -------------------------------------------------------------------------------------------------
    /**
     * Right overlay close button
     */
    $('.js-overlay-close').on('click', function () {
        'use strict';

        closeOverlayRight();
    });

    /**
     * Right overlay previous button
     * To change the order that the buttons select pins on the layer, change the order of the content in the contentStore (Re-order the JSON)
     */
    $overlayPrevButton.on('click', function () {
        if (currentLayer) {
            var pinsArray = getObjectProperties(contentStore[currentLayer]['pins']);
            var currentIndex = pinsArray.indexOf(currentOverlay);
            var prev = null;

            if (currentIndex - 1 > -1) {
                prev = pinsArray[currentIndex - 1];
            } else {
                prev = pinsArray[pinsArray.length - 1];
            }

            $('#' + prev).find('.js-pin').trigger('click');
        }

        if (currentCaseStudy) {
            var $activePins = $('.js-case-study-pin.is-active');
            var $currentPin = $activePins.filter(function () {
                return $(this).hasClass('is-selected');
            });
            var _currentIndex = $activePins.index($currentPin);

            if ($($activePins[_currentIndex - 1]).length) {
                $($activePins[_currentIndex - 1]).trigger('click');
            } else {
                $activePins.last().trigger('click');
            }
        }
    });

    /**
     * Right overlay next button
     * To change the order that the buttons select pins on the layer, change the order of the content in the contentStore (Re-order the JSON)
     */
    $overlayNextButton.on('click', function () {
        if (currentLayer) {
            var pinsArray = getObjectProperties(contentStore[currentLayer]['pins']);
            var currentIndex = pinsArray.indexOf(currentOverlay);
            var next = null;

            if (currentIndex + 1 <= pinsArray.length - 1) {
                next = pinsArray[currentIndex + 1];
            } else {
                next = pinsArray[0];
            }

            $('#' + next).find('.js-pin').trigger('click');
        }

        if (currentCaseStudy) {
            var $activePins = $('.js-case-study-pin.is-active');
            var $currentPin = $activePins.filter(function () {
                return $(this).hasClass('is-selected');
            });
            var _currentIndex2 = $activePins.index($currentPin);

            console.log('next' + _currentIndex2);

            if ($($activePins[_currentIndex2 + 1]).length) {
                $($activePins[_currentIndex2 + 1]).trigger('click');
            } else {
                $activePins.first().trigger('click');
            }
        }
    });

    /**
     * Explore button
     */
    $explore.on('click', function () {
        console.log('explore button');

        if (currentLayer) {
            var pinsArray = getObjectProperties(contentStore[currentLayer]['pins']);

            $('#' + pinsArray[0]).find('.js-pin').trigger('click');

            if (debug) {
                console.log(currentLayer);
                console.log(contentStore[currentLayer]);
                console.log($('#' + pinsArray[0]));
            }
        }

        if (currentCaseStudy) {
            $('.js-case-study-pin.is-active').first().trigger('click');

            if (debug) {
                console.log(currentLayer);
                console.log(contentStore[currentLayer]);
                console.log($('.js-case-study-pin.is-active'));
            }
        }
    });

    // Case Study functionality
    // -------------------------------------------------------------------------------------------------
    // Function to hide the case studies overlay
    function hideCaseStudies() {
        if (isMobile) {
            $caseStudies.hide();
        } else {
            TweenMax.to($caseStudies, slideAnimationSpeed, {
                opacity: 0,
                onComplete: function onComplete() {
                    $caseStudies.hide();

                    currentCaseStudy = null;
                }
            });
        }
    }

    /**
     * Case Study buttons in the left hand sidebar
     */
    $caseStudyButtons.on('click', function () {
        if (!isTweening) {
            var caseStudy = $(this).data('caseStudy');

            // Deselect main nav
            $navItems.removeClass('is-active');

            // Set case study nav classes
            $caseStudyButtons.not($(this)).removeClass('is-active');
            $(this).addClass('is-active');

            closeOverlayRight();

            if (currentCaseStudy !== caseStudy) {

                // Spread the layers and show the relevant case study content
                showCaseStudyPreview(caseStudy);

                // Disable all the case study icons
                $caseStudyPins.addClass('is-disabled').removeClass('is-selected is-active');

                // Go through each section and show pins based on the json content
                enablePins($(this).data('caseStudy'), 'blue');
                enablePins($(this).data('caseStudy'), 'green');
                enablePins($(this).data('caseStudy'), 'yellow');
                enablePins($(this).data('caseStudy'), 'orange');
                enablePins($(this).data('caseStudy'), 'pink');

                // Show Case Study general content
                // Apply the Title
                var title = applyPageContent('title', [currentCaseStudy]);

                // here

                // Apply the content
                applyPageContent('content', [currentCaseStudy]);

                // Google Analytics Event tracking
                trackAnalyticEvent('Case study', 'Opened', title);

                $customScrollbars.perfectScrollbar('update');
            }
        }
    });

    /**
     * Case Study Icons (all 45)
     */
    $caseStudyPins.on('click', function () {
        $caseStudyPins.not($(this)).removeClass('is-selected');

        $(this).addClass('is-selected');

        var clickedPinColor = $(this).data('color');

        var clickedPinIcon = $(this).data('icon');

        // Apply the Title
        var title = applyPinContent('title', [currentCaseStudy, clickedPinColor, clickedPinIcon]);

        // Apply the content
        applyPinContent('content', [currentCaseStudy, clickedPinColor, clickedPinIcon]);

        // Google Analytics Event tracking
        trackAnalyticEvent('Case study pin', 'clicked', title);

        $customScrollbars.perfectScrollbar('update');

        $rightOverlay.addClass('is-visible');

        var $activePins = $('.js-case-study-pin.is-active');
        var currentIndex = $activePins.index($(this));

        if (currentIndex === 0) {
            $overlayPrevButton.addClass('is-hidden');
            $overlayNextButton.removeClass('is-hidden');
        } else if (currentIndex + 1 === $activePins.length) {
            $overlayNextButton.addClass('is-hidden');
            $overlayPrevButton.removeClass('is-hidden');
        } else {
            $overlayPrevButton.removeClass('is-hidden');
            $overlayNextButton.removeClass('is-hidden');
        }
    });

    /**
     * Iterate through defined section and show pins based on the json content
     * @param caseStudy
     * @param color
     */
    function enablePins(caseStudy, color) {
        var caseStudyContent = contentStore[caseStudy];
        var colorCaseStudies = Object.keys(caseStudyContent[color]);

        colorCaseStudies.forEach(function (element, index, array) {
            $('.js-case-studies-' + color).find('.js-' + element).removeClass('is-disabled').addClass('is-active');
        });
    }

    /**
     * Manage the animation of spreading the layers and hiding all buildings that are not related to the JSON content
     * @param caseStudy
     */
    function showCaseStudyPreview(caseStudy) {
        isTweening = true;
        isCaseStudy = true;
        currentCaseStudy = caseStudy;

        var caseStudyTimeline = new TimelineLite();

        if (isMobile) {
            $caseStudies.show();
            TweenMax.set($caseStudies, {
                opacity: 1,
                onComplete: function onComplete() {
                    isTweening = false;
                    currentLayer = null;
                    currentOverlay = null;

                    resetLayerPositions();
                }
            });
        } else {
            // Tween all layers so they are evenly spread
            caseStudyTimeline.to($layers[0], 0.3, {
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: -150
            }, 0).to($layers[1], 0.3, {
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: -90
            }, 0).to($layers[2], 0.3, {
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: -30
            }, 0).to($layers[3], 0.3, {
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: 30
            }, 0).to($layers[4], 0.3, {
                opacity: 1,
                scale: 1,
                transformOrigin: "center bottom",
                y: 90,
                onComplete: function onComplete() {
                    $caseStudies.show();
                }
            }, 0).add(TweenMax.fromTo($caseStudies, slideAnimationSpeed, {
                opacity: 0
            }, {
                opacity: 1,
                onComplete: function onComplete() {
                    isTweening = false;
                    currentLayer = null;
                    currentOverlay = null;

                    resetLayerPositions();
                }
            }), 2);

            // Make layer items transparent
            $('.js-layer > g').each(function () {
                var _this = this;

                var classList = $(this).attr('class');
                var id = $(this).attr('id');

                if (!classList) {
                    classList = '';
                }

                if (!id) {
                    id = '';
                }

                // Set the base opacity
                if (classList.indexOf('js-base') > -1) {
                    // Make the base transparent
                    TweenMax.to($(this), 0.3, { 'opacity': 0.8 });
                } else {
                    // Set the building opacity(s)
                    if (id.indexOf('Building') > -1) {
                        (function () {
                            TweenMax.to($(_this), 0.3, { 'opacity': 1 });

                            // What colour layer is this?
                            var colorContent = contentStore[caseStudy][$(_this).parents('.js-layer').attr('data-color')];

                            // Get an array of SVG values from the JSON
                            var svgJsonIds = Object.keys(colorContent).reduce(function (previous, current) {
                                previous[current] = colorContent[current].svg;

                                return previous;
                            }, {});

                            // Convert svgIds to array of values
                            var currentLayerCaseStudyContent = Object.keys(svgJsonIds).map(function (key) {
                                return svgJsonIds[key];
                            });

                            // Find each child group, check it's ID in the JSON SVG ID array
                            $(_this).find('> g').each(function () {
                                // If not found, hide the group
                                if (currentLayerCaseStudyContent.indexOf($(this).attr('id')) === -1) {
                                    TweenMax.to($(this), 0.3, { 'opacity': 0 });
                                } else {
                                    if (currentLayer) {
                                        TweenMax.to([$(this), $(this).find('g:not(.js-pin)')], 0.3, { 'opacity': 1 });
                                    }
                                }

                                // Hide all pins
                                TweenMax.to($pins, 0.3, { 'opacity': 0 });
                            });
                        })();
                    } else {
                        // Hide unimportant things
                        TweenMax.to($(this), 0, { 'opacity': 0 });
                    }
                }
            });
        }
        currentLayer = null;
        currentOverlay = null;
    }

    // Intro Overlay
    // -------------------------------------------------------------------------------------------------
    /**
     * Intro Overlay animation and click functionality
     * @type {*|HTMLElement}
     */
    $introClose.on('click', function () {
        if (init) {
            if (isMobile) {
                $introOverlay.remove();
            } else {
                TweenMax.to($introClose, 0.15, {
                    'scale': 1.5,
                    'opacity': 0
                });

                TweenMax.to($introBox, 1.4, {
                    'opacity': 0
                });

                TweenMax.to($introOverlay, 0.5, {
                    'delay': 0.4,
                    'opacity': 0,
                    onComplete: function onComplete() {
                        $introOverlay.remove();
                    }
                });
            }
        }
    });

    $introOverlay.on('click', function (e) {
        if (init) {
            if (isMobile) {
                $introOverlay.remove();
            } else {
                TweenMax.to($introBox, 1.4, {
                    'opacity': 0
                });

                TweenMax.to($introOverlay, 0.5, {
                    'delay': 0.2,
                    'opacity': 0,
                    onComplete: function onComplete() {
                        $introOverlay.remove();
                    }
                });
            }
        }
    });

    // Mobile Overlay
    // -------------------------------------------------------------------------------------------------
    /**
     * Intro Overlay animation and click functionality
     * @type {*|HTMLElement}
     */
    $mobileClose.on('click', function () {
        if (init) {
            $mobileOverlay.remove();
        }
    });

    $mobileOverlay.on('click', function (e) {
        if (init) {
            $mobileOverlay.remove();
        }
    });
})(jQuery);